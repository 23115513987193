import styled from "styled-components"
import { Link } from "gatsby"

import ErrorBg from "src/assets/img/CaseDetails/case1_bg.png"

export const ErrorButton = styled(Link)`
  padding: 10px 36px;
  background-color: ${props => props.theme.color.green};
  font-size: 16px;
  line-height: 1.4;
  display: inline-block;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
  transition: 0.4s all ease;
  border: 2px solid ${props => props.theme.color.green};
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.color.green};
  }
  @media screen and (max-width: 1441px) {
    font-size: 12px;
    padding: 7px 28px;
  }
`

export const ErrorCode = styled.div`
  font-size: 250px;
  color: ${props => props.theme.color.green};
  font-weight: 700;
  @media screen and (max-width: 1441px) {
    font-size: 120px;
  }
`

export const ErrorPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  background-image: url(${ErrorBg});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 50%;

  @media screen and (max-width: 769px) {
    background-image: none;
    align-items: center;
  }
`
export const ErrorMessage = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  color: ${props => props.theme.color.black};
  @media screen and (max-width: 1441px) {
    font-size: 14px;
  }
`

export const ErrorPageWrapper = styled.div`
  width: 55%;
  min-height: 400px;
`
